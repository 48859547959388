import React from "react";
import Typography from "@mui/material/Typography";
import { List, ListItemButton, ListItemText, Collapse } from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import SideBarItem from "./SideBarItem";


export default function SideBarNestedItem({ item, isOpen, onOpenItem }) {
  let navigate = useNavigate();

  const handleItemClick = () => {
    onOpenItem(); // Notify parent to toggle the open state

    if (item.route) {
      navigate(item.route);
    }
  };

  return (
    <div className="side-nav-nested-menu-container">
      <ListItemButton
        onClick={handleItemClick}
        className="side-nav-nested-menu"
      >
        <ListItemText
          sx={{
            height: "100%",
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            noWrap
            sx={{ fontSize: "14px" }}
            color="primaryText.dark"
          >
            {item.label}
          </Typography>
        </ListItemText>
        {isOpen ? (
          <ExpandLess
            style={{ color: "grey" }}
            className="side-nav-nested-menu-icon"
          />
        ) : (
          <ExpandMore
            style={{ color: "grey" }}
            className="side-nav-nested-menu-icon"
          />
        )}
      </ListItemButton>
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {item.children.map((child, index) => (
            <SideBarItem
              item={child}
              classes="side-nav-nested-link"
              key={index}
            />
          ))}
        </List>
      </Collapse>
    </div>
  );
}

// old code
// export default function SideBarNestedItem({ item, isOpen, onOpenItem }) {
//   let navigate = useNavigate();

//   const [open, setOpen] = useState(item.initiallyOpen || true);

//   const handleItemClick = () => {
//     setOpen(!open);

//     if (item.route) {
//       navigate(item.route);
//     }
//   };

//   return (
//     <div className="side-nav-nested-menu-container">
//       <ListItemButton
//         onClick={handleItemClick}
//         className="side-nav-nested-menu"
//       >
//         <ListItemText
//           sx={{
//             height: "100%",
//             width: "100%",
//           }}
//         >
//           <Typography
//             variant="body1"
//             noWrap
//             sx={{ fontSize: "14px" }}
//             color="primaryText.dark"
//           >
//             {item.label}
//           </Typography>
//         </ListItemText>
//         {open ? (
//           <ExpandLess
//             style={{ color: "grey" }}
//             className="side-nav-nested-menu-icon"
//           />
//         ) : (
//           <ExpandMore
//             style={{ color: "grey" }}
//             className="side-nav-nested-menu-icon"
//           />
//         )}
//       </ListItemButton>
//       <Collapse in={open} timeout="auto" unmountOnExit>
//         <List component="div" disablePadding>
//           {item.children.map((children, index) => {
//             return (
//               <SideBarItem
//                 item={children}
//                 classes="side-nav-nested-link"
//                 key={index}
//               />
//             );
//           })}
//         </List>
//       </Collapse>
//     </div>
//   );
// }